
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/modal/Overview.vue";
import BasicExample from "@/view/pages/resources/documentation/base/modal/BasicExample.vue";
import FullScreen from "@/view/pages/resources/documentation/base/modal/FullScreen.vue";

export default defineComponent({
  name: "modal",
  components: {
    Overview,
    BasicExample,
    FullScreen,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Modal");
    });
  },
});
